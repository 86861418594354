<template>
    <div>
        <Header />
        <mdb-parallax style="margin-top: -13px"
            src="/img/bgs/bg2.jpg"
            height="full"
            factor="1"
            >
            <mdb-mask overlay="blue-slight" flexCenter>
                <mdb-row class="mb-5 pb-5 text-center white-text">
                <mdb-col md="12" xl="12" class="mx-auto">
                    <h1 class="display-4 font-weight-bold animated fadeInDown">Equipo</h1>
                </mdb-col>
                </mdb-row>
            </mdb-mask>
        </mdb-parallax>
            <mdb-container>
                <mdb-row>
                    <div class="text-center mt-5 pt-3" style="width: 100%;">
                        <div class="ar-main-title mb-2 mt-5" style="font-size: 30px;">C-Suite</div>
                    </div>
                    <mdb-col lg="4" md="6">
                        <mdb-card wide class="pt-5 px-3 arvore-team-card">
                            <mdb-view hover cascade>
                                <a href="#!">
                                <mdb-card-image
                                    src="/img/team/cesar.jpg"
                                    alt="César Manrique"
                                    class="img-member"
                                ></mdb-card-image>
                                <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
                                </a>
                            </mdb-view>
                            <mdb-card-body class="text-center" cascade>
                                <mdb-card-title class="card-title">
                                    <strong>César Manrique</strong>
                                </mdb-card-title>
                                <h6 class="blue-text">
                                <strong>CEO</strong>
                                </h6>
                                <mdb-btn tag="a" target="_blank" href="https://www.linkedin.com/in/manrique-cesar" size="md" floating class="btn-li" icon="linkedin-in" fab></mdb-btn>
                                <hr style="margin-bottom: 16px; margin-top: 34px;">
                                <mdb-btn size="sm" color="primary" @click.native="toggleMemberModal('cesar')">Acerca de mí</mdb-btn>
                            </mdb-card-body>
                        </mdb-card>
                    </mdb-col>
                    <mdb-col lg="4" md="6">
                        <mdb-card wide class="pt-5 px-3 arvore-team-card">
                            <mdb-view hover cascade>
                                <a href="#!">
                                <mdb-card-image
                                    src="/img/team/giovanni.jpg"
                                    alt="César Manrique"
                                    class="img-member"
                                ></mdb-card-image>
                                <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
                                </a>
                            </mdb-view>
                            <mdb-card-body class="text-center" cascade>
                                <mdb-card-title class="card-title">
                                    <strong>Giovanni Russildi</strong>
                                </mdb-card-title>
                                <h6 class="blue-text">
                                    <strong>Chief Operations Officer</strong>
                                </h6>
                                <mdb-btn tag="a" target="_blank" href="https://www.linkedin.com/in/giovanni-vicente-russildi-gallegos-081b03b2" size="md" floating class="btn-li" icon="linkedin-in" fab></mdb-btn>
                                <hr style="margin-top: 35px; margin-bottom: 16px">
                                <mdb-btn size="sm" color="primary" @click.native="toggleMemberModal('giovanni')">Acerca de mí</mdb-btn>
                            </mdb-card-body>
                        </mdb-card>
                    </mdb-col>  
                    <mdb-col lg="4" md="6">
                        <mdb-card wide class="pt-5 px-3 arvore-team-card">
                            <mdb-view hover cascade>
                                <a href="#!">
                                <mdb-card-image
                                    src="/img/team/cristian.jpg"
                                    alt="Christian Rodriguez"
                                    class="img-member"
                                ></mdb-card-image>
                                <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
                                </a>
                            </mdb-view>
                            <mdb-card-body class="text-center" cascade>
                                <mdb-card-title class="card-title">
                                    <strong>Christian Rodríguez</strong>
                                </mdb-card-title>
                                <h6 class="blue-text">
                                    <strong>Chief Research and Development Officer</strong>
                                </h6>
                                <mdb-btn tag="a" target="_blank" href="https://www.linkedin.com/in/christian-rodr%C3%ADguez-b05646a6" size="md" floating class="btn-li" icon="linkedin-in" fab></mdb-btn>
                                <hr>
                                <mdb-btn size="sm" color="primary" @click.native="toggleMemberModal('christian')">Acerca de mí</mdb-btn>
                            </mdb-card-body>
                        </mdb-card>
                    </mdb-col>
                    <mdb-col lg="4" md="6">
                        <mdb-card wide class="pt-5 px-3 arvore-team-card">
                            <mdb-view hover cascade>
                                <a href="#!">
                                <mdb-card-image
                                    src="/img/team/miguel.jpg"
                                    alt="Miguel García"
                                    class="img-member"
                                ></mdb-card-image>
                                <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
                                </a>
                            </mdb-view>
                            <mdb-card-body class="text-center" cascade>
                                <mdb-card-title class="card-title">
                                    <strong>Miguel García</strong>
                                </mdb-card-title>
                                <h6 class="blue-text">
                                    <strong>Finance Manager</strong>
                                </h6>
                                <mdb-btn tag="a" target="_blank" href="https://www.linkedin.com/in/miguel-angel-garc%C3%ADa-vel%C3%A1zquez-b7454296" size="md" floating class="btn-li" icon="linkedin-in" fab></mdb-btn>
                                <hr style="margin-top: 32px; margin-bottom: 18px;">
                                <mdb-btn size="sm" color="primary" @click.native="toggleMemberModal('miguel')">Acerca de mí</mdb-btn>
                            </mdb-card-body>
                        </mdb-card>
                    </mdb-col>
                    <mdb-col lg="4" md="6">
                        <mdb-card wide class="pt-5 px-3 arvore-team-card">
                            <mdb-view hover cascade>
                                <a href="#!">
                                <mdb-card-image
                                    src="/img/team/tonatiuth.jpg"
                                    alt="Tonatiuth Tenorio"
                                    class="img-member"
                                ></mdb-card-image>
                                <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
                                </a>
                            </mdb-view>
                            <mdb-card-body class="text-center" cascade>
                                <mdb-card-title class="card-title">
                                    <strong>Tonatiuth Tenorio</strong>
                                </mdb-card-title>
                                <h6 class="blue-text">
                                    <strong>Chief Technology Officer</strong>
                                </h6>
                                <mdb-btn tag="a" target="_blank" href="https://www.linkedin.com/in/tonatiuh-tenorio-689ab675" size="md" floating class="btn-li" icon="linkedin-in" fab></mdb-btn>
                                <hr style="margin-top: 35px; margin-bottom: 18px;">
                                <mdb-btn size="sm" color="primary" @click.native="toggleMemberModal('tonatiuth')">Acerca de mí</mdb-btn>
                            </mdb-card-body>
                        </mdb-card>
                    </mdb-col>
                    <mdb-col lg="4" md="6">
                        <mdb-card wide class="pt-5 px-3 arvore-team-card">
                            <mdb-view hover cascade>
                                <a href="#!">
                                <mdb-card-image
                                    src="/img/team/benhur.jpg"
                                    alt="Benhur"
                                    class="img-member"
                                ></mdb-card-image>
                                <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
                                </a>
                            </mdb-view>
                            <mdb-card-body class="text-center" cascade>
                                <mdb-card-title class="card-title">
                                    <strong>Ben Hur Cortez</strong>
                                </mdb-card-title>
                                <h6 class="blue-text">
                                <strong>Renewable Energy Operations Manager</strong>
                                </h6>
                                <mdb-btn tag="a" target="_blank" href="https://www.linkedin.com/in/ben-hur-cortez-b00b68a4" size="md" floating class="btn-li" icon="linkedin-in" fab></mdb-btn>
                                <hr style="margin-top: 18px; margin-bottom: 17px;">
                                <mdb-btn size="sm" color="primary" @click.native="toggleMemberModal('benhur')">Acerca de mí</mdb-btn>
                            </mdb-card-body>
                        </mdb-card>
                    </mdb-col>
            </mdb-row>
        </mdb-container>
        <mdb-container>
            <mdb-row>
                <div class="text-center mt-5 pt-3" style="width: 100%;">
                    <div class="ar-main-title mb-2 mt-5" style="font-size: 30px;">Colaboradores Estratégicos</div>
                </div>
                <mdb-col offsetLg="2" lg="8">
                    <mdb-carousel
                        :items="14"
                        multi
                        one
                        indicators
                        controlls
                        leftIcon="angle-double-left"
                        rightIcon="angle-double-right"
                        navColor="info"
                        indicatorsColor="info-color"
                        slide
                        >
                        <template #[i+1] v-for="(item, i) in collabsItems">
                            <mdb-card :key="i">
                            <mdb-card-image :src="item.img" alt="Card image cap"></mdb-card-image>
                            <mdb-card-body class="text-center">
                                <mdb-card-title>{{item.name}}</mdb-card-title>
                                <mdb-card-text>{{ item.role }}</mdb-card-text>
                            </mdb-card-body>
                            </mdb-card>
                        </template>
                    </mdb-carousel>
                </mdb-col>
            </mdb-row>
        </mdb-container>
        <div class="text-center mt-5 pt-3" style="width: 100%;">
            <div class="ar-main-title mb-2 mt-5" style="font-size: 30px;">Consejo consultivo</div>
        </div>
        <mdb-container class="mb-5 pb-3">
            <mdb-row>
                <mdb-carousel testimonial controlls :items="3">
                    <template #[i+1] v-for="(item, i) in consultiveItems">
                        <mdb-testimonial class="text-center" :key="i">
                            <mdb-avatar
                                wrapperClass="z-depth-1 mb-4"
                                :width="200"
                                tag="img"
                                :src="item.img"
                            />
                            <h4 class="font-weight-bold">{{item.name}}</h4>
                            <p>
                                {{item.description}}
                            </p>
                        </mdb-testimonial>
                    </template>
                </mdb-carousel>
            </mdb-row>
            <mdb-col class="mt-5 pt-5">
                <h4 class="ar-main-title" style="font-size: 22px;">Organizaciones Internacionales</h4>
            </mdb-col>
            <mdb-row>
                <mdb-col class="text-center" md="4" offsetMd="4">
                    <p class="text-justify">Arvore como <b class="ar-text">miembro de GEIDCO,</b> forma parte de una red global comprometida con la transformación del sector energético hacia un modelo más sostenible y resiliente.</p>
                    <img class="img-fluid mt-5" src="/img/consultive/global.jpg" />
                    <a class="btn btn-arvore mt-3" href="https://m.geidco.org.cn/?lang=en" target="_blank">Visitar</a>
                </mdb-col>
            </mdb-row>
            <mdb-col class="mt-5 pt-5">
                <h4 class="ar-main-title" style="font-size: 22px;">Aliados</h4>
            </mdb-col>
            <mdb-row>
                <section>
                    <mdb-carousel
                    :items="13"
                    multi
                    one
                    class="fa"
                    indicators
                    controlls
                    leftIcon="angle-double-left"
                    rightIcon="angle-double-right"
                    navColor="info"
                    indicatorsColor="info-color"
                    slide
                    >
                    <template #[i+1] v-for="(img, i) in aliadosItems">
                        <mdb-card :key="i">
                            <mdb-card-image style="height:100% !important" :src="img"></mdb-card-image>
                        </mdb-card>
                    </template>
                    </mdb-carousel>
                </section>
            </mdb-row>
        </mdb-container>
        <mdb-modal :show="modal" @close="toggleMemberModal('')">
            <mdb-modal-header style="background-color: #11619d; color: white;">
                <mdb-modal-title style="font-weight: bold;">{{ selected.name != undefined ? selected.name : '' }}</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
                <mdb-row>
                    <mdb-col class="text-left">
                        <p class="ar-text text-justify">{{ selected.bio != undefined ? selected.bio : '' }}</p>
                    </mdb-col>    
                </mdb-row> 
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn outline="primary" darkWaves @click="toggleMemberModal('')">Cerrar</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>
        <Footer />
    </div>
</template>
<script>
    import Header from "./Header";
    import HeroSection from "./HeroSection";
    import Footer from "./Footer";
    import { mdbContainer, mdbRow, mdbCol, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbCard, mdbCardBody, mdbView, mdbMask, mdbCardImage, mdbCardText, mdbCardTitle, mdbCarousel, mdbBtn, mdbAvatar, mdbTestimonial, mdbIcon, mdbJumbotron, mdbParallax } from "mdbvue";
    export default {
        name: "Team",
        components: {
            mdbRow,
            mdbCol,
            mdbContainer,
            Header,
            Footer,
            HeroSection,
            mdbModal, 
            mdbModalHeader, 
            mdbModalTitle, 
            mdbModalBody,
            mdbModalFooter,
            mdbCard, 
            mdbCardBody, 
            mdbView, 
            mdbMask, 
            mdbCardImage, 
            mdbCardText, 
            mdbCardTitle,
            mdbCarousel,
            mdbBtn,
            mdbAvatar,
            mdbTestimonial,
            mdbIcon,
            mdbJumbotron,
            mdbParallax
        },
        data(){
            return {
                modal: false,
                team: [
                    {
                        id: "cesar",
                        name: "César Manrique",
                        bio: "Soy un biólogo y candidato a Doctor en Ciencias por la UNCo en Argentina, mis intereses se centran en el paleoclíma y la macroecología de ambientes áridos y forestales. A partir del uso de herramientas como los SIG´s, analizo y visualizo datos espaciales para identificar patrones y relaciones climaticas y ecológicas clave. Trabajo en colaboración con equipos y aliados para abordar desafíos ambientales y promover prácticas sostenibles. Mi experiencia en conectividad del paisaje, servicios ecosistémicos y captura de CO2 me permite contribuir en proyectos e iniciativas que buscan opciones de impacto sostenible."
                    },
                    {
                        id: "giovanni",
                        name: "Giovanni V. Russildi",
                        bio: "Soy biólogo con una Maestría en Ciencias Biológicas Por la UNAM con un enfoque en ecología del paisaje y diversidad de herpetofauna en sistemas tropicales. Tengo experiencia en la coordinación y supervisión de proyectos que abarcan investigación, conservación de biodiversidad, apoyo social y vinculación entre ONG´s y OG´s. Mi enfoque se centra en comprender las interacciones entre la biodiversidad y su entorno, y he trabajado en colaboración con diversas organizaciones para lograr una gestión efectiva de los recursos naturales. Estoy comprometido con la protección de la biodiversidad, el desarrollo sostenible y cambio climático."
                    },
                    {
                        id: "miguel",
                        name: "Miguel A. García V.",
                        bio: "Soy un biólogo graduado de la UMSNH con una amplia experiencia en roles de liderazgo. He sido jefe de área, delegado regional, secretario en cargos públicos y supervisor subcoordinador operativo en programas nacionales federales. Mi formación y trayectoria me han brindado un profundo conocimiento administrativo, así como habilidades en la coordinación de equipos y la formulación de políticas públicas. Estoy comprometido con la conservación del medio ambiente y el desarrollo sostenible, aplicando mis conocimientos y habilidades en nuevos desafíos y oportunidades."
                    },
                    {
                        id: "christian",
                        name: "Christian L. Rodriguez Enriquez",
                        bio: "Soy un biólogo con una Maestría en Ciencias y candidato a Doctor por el INECOL. Con orientación en comportamiento animal y conservación de especies. Mis intereses se centran en el control de plagas en la agricultura mediante el uso de estrategias de Manejo Integrado y Control Biológico mediante el desarrollo de  soluciones sostenibles y efectivas  que promuevan prácticas agrícolas respetuosas con el medio ambiente. Estoy comprometido en contribuir al avance de la agricultura sostenible, mejorando la eficiencia y la rentabilidad de los sistemas agrícolas mientras se minimiza el impacto ambiental."
                    },
                    {
                        id: "tonatiuth",
                        name: "Tonatiuth Tenorio",
                        bio: "Soy un ingeniero químico y poseo una Maestría en Ingeniería y Proyectos por la UNAM. Mi experiencia se centra en la gerencia de proyectos industriales, así como en el control y supervisión de instalaciones electromecánicas en la industria papelera y alimenticia. Mi formación académica y trayectoria laboral me han brindado un sólido conocimiento en ingeniería química y la capacidad de planificar, ejecutar y supervisar proyectos complejos. Estoy comprometido con la mejora continua y estoy listo para afrontar nuevos desafíos en el campo científico e industrial."
                    },
                    {
                        id: "benhur",
                        name: "Ben Hur Cortez",
                        bio: "Soy un Aerologista con amplia experiencia en energías renovables, especialmente en sistemas fotovoltaicos. Mi formación como Aerologista me ha brindado una sólida base en el análisis del clima y las condiciones atmosféricas y aplico este conocimiento para impulsar la integración de alternativas sustentables en el sector energético. A lo largo de mi carrera, he trabajado en el diseño, instalación y monitoreo de sistemas fotovoltaicos, aprovechando mi experiencia en el análisis del clima y la radiación solar. Colaboro en proyectos de energía solar en diversas aplicaciones, desde instalaciones residenciales, comerciales, bombeo solar entre otras aplicaciones. Mi enfoque principal ha sido optimizar el rendimiento y la eficiencia de los sistemas fotovoltaicos, asegurando una captación máxima de energía solar y su integración adecuada en la red eléctrica. Además, he participado en el desarrollo de estrategias de almacenamiento de energía y en la gestión inteligente de la energía generada."
                    }
                ],
                selected: {},
                device: '',
                aliadosItems: [
                    "/img/consultive/iies-carr.jpg",
                    "/img/consultive/bws-carr.jpg",
                    "/img/consultive/cema-carr.jpg",
                    "/img/consultive/cicimar-carr.jpg",
                    "/img/consultive/eca-carr.jpg",
                    "/img/consultive/ener-carr.jpg",
                    "/img/consultive/fab-carr.jpg",
                    "/img/consultive/gea-carr.jpg",
                    "/img/consultive/ibunam-carr.jpg",
                    "/img/consultive/unam-carr.jpg",
                    "/img/consultive/mang-carr.jpg",
                    "/img/consultive/sunp-carr.jpg",
                    "/img/consultive/ugfg-carr.jpg"
                ],
                collabsItems: [
                    {
                        id: "ar", 
                        name: "Anayatzin Ramírez",
                        role: "Interdisciplinary Development Studies",
                        img: "/img/collabs/ar.png"
                    },
                    {
                        id: "clox", 
                        name: "Cloe X. Pérez",
                        role: "Environmental Geography Specialist",
                        img: "/img/collabs/clox.png"
                    },
                    {
                        id: "lt", 
                        name: "Leonardo Tenorio",
                        role: "Physical Oceanography",
                        img: "/img/collabs/lt.png"
                    },
                    {
                        id: "dd", 
                        name: "Diana Duque",
                        role: "Social Media Manager",
                        img: "/img/collabs/dd.png"
                    },
                    {
                        id: "dm", 
                        name: "Diana Manrique",
                        role: "Anthropology of Indigenous People",
                        img: "/img/collabs/dm.png"
                    },
                    {
                        id: "mm", 
                        name: "Marco Meza",
                        role: "Web Developer",
                        img: "/img/collabs/mm.png"
                    },
                    {
                        id: "db", 
                        name: "Dulce Bocanegra",
                        role: "Plant Macroecology and Soils",
                        img: "/img/collabs/db.png"
                    },
                    {
                        id: "fn", 
                        name: "Frida N. Guiza",
                        role: "Socioenvironmental Vulnerability",
                        img: "/img/collabs/fn.png"
                    },
                    {
                        id: "mp", 
                        name: "Miguel Piñón",
                        role: "Continental Aquatic Ecosystems Specialist",
                        img: "/img/collabs/mp.png"
                    },
                    {
                        id: "yv", 
                        name: "Yanet Villaseñor",
                        role: "Birds Conservation Specialist",
                        img: "/img/collabs/yv.png"
                    },
                    {
                        id: "gd", 
                        name: "Gabriela Dominguez",
                        role: "Plant Ecologist",
                        img: "/img/collabs/gd.png"
                    },
                    {
                        id: "om", 
                        name: "Omar Marín",
                        role: "Environmental Management and Assessment",
                        img: "/img/collabs/om.png"
                    },
                    {
                        id: "vo", 
                        name: "Veronica Osuna",
                        role: "Forest Declination and Climate Change",
                        img: "/img/collabs/vo.png"
                    },
                    {
                        id: "rm", 
                        name: "Rafael Morales",
                        role: "Ecohydrology",
                        img: "/img/collabs/rm.png"
                    },
                    
                ],
                consultiveItems: [
                    {
                        img: "/img/consultive/iies-carr.jpg",
                        name: "Dr. Víctor Arroyo Rodríguez",
                        description: "Profesor Investigador Titular B (SNIII) UNAM-IIES, Laboratorio de ecología en paisajes fragmentados. Líneas de investigación: Dinámica de poblaciones y comunidades de animales y plantas en paisajes fragmentados, diversidad y estructura de selvas fragmentadas, ecología y conservación de primates."
                    },
                    {
                        img: "/img/consultive/fab-carr.jpg",
                        name: "Dr. Ricardo Pérez Munguía",
                        description: "Profesor Investigador titular B (SNI I), UMSNH - Facultad de biología, laboratorio de ecología de sistemas lóticos. Líneas de investigación: Ecología de macroinvertebrados acuáticos e indicadores biológicos, ecología del paisaje."
                    },
                    {
                        img: "/img/consultive/unam-carr.jpg",
                        name: "Dr. Omar Hernández Ordóñez",
                        description: "Técnico académico, UNAM-IIES, laboratorio de ecología y manejo de bosques tropicales. Con línea de investigación en conservación y manejo de reptiles y anfibios en bosques tropicales."
                    }
                ]
            }
        },
        methods: {
            toggleMemberModal(member){
                if(member){
                    let selected = this.team.filter((value) => value.id === member);
                    this.selected = selected[0];
                } else {
                    this.selected = {
                        name: '',
                        bio: ''
                    }
                }
                this.modal = !this.modal;
            },
        },
        mounted(){
            if(window.innerWidth > 990){
                this.device = 'desktop';
            } else {
                this.device = 'mobile';
            }
        }
    }
</script>
<style lang="scss" scoped>
    .c-container .c-row {
    margin: 20px;
    text-align: center;
    }

    .img-member {
        -webkit-filter: grayscale(100%) !important; /* Safari 6.0 - 9.0 */
        filter: grayscale(100%) !important; 
        border-radius: 50%;
    }

    .card.card-cascade .view.view-cascade  {
         border-radius: 50%;
    }

    .c-container .c-row img {
     margin: 0 20px;
    }

    .card {
        -webkit-box-shadow: 0 0px 0px 0 rgba(0,0,0,0.0), 0 2px 10px 0 rgba(0,0,0,0.0);
        box-shadow: 0 0px 0px 0 rgba(0,0,0,0.0), 0 2px 10px 0 rgba(0,0,0,0.0);
    }

    .z-depth-1, .chip:active {
        -webkit-box-shadow: 0 0px 0px 0 rgba(0,0,0,0.0), 0 2px 10px 0 rgba(0,0,0,0.0) !important;
        box-shadow: 0 0px 0px 0 rgba(0,0,0,0.0), 0 2px 10px 0 rgba(0,0,0,0.0) !important;
    }

    .card.card-cascade.wider .card-body.card-body-cascade {
        -webkit-box-shadow: 0 0px 0px 0 rgba(0,0,0,0.16), 0 0px 0px 0 rgba(0,0,0,0.12);
         box-shadow: 0 0px 0px 0 rgba(0,0,0,0.16), 0 0px 0px 0 rgba(0,0,0,0.12); 
         padding: 0;
         padding-top: 15px;
    }

    .team-member-description {
        font-size: 12px !important;
    }

    .arvore-team-card {
        max-width: 250px; 
        margin: auto;
        height: 100%;
    }

    .consultive-card {
        margin: auto;
        height: 100%;
    }

    .card-title {
        font-size: 20px;
    }

    .arvore-logo-team {
        filter: grayscale(0); 
        border: none; 
        max-width: 200px; 
        position: absolute; 
        top: 60%; 
        left: 0%; 
        transform: translate(-50%, -50%);
    }

    .consultive-description {
        color: grey;
        hyphens: auto;
        text-align: justify;
        padding-top: 20px;
    }
    
    .jumbotron {
        -webkit-box-shadow: 0 0px 0px 0 rgba(0,0,0,0.0), 0 0px 0px 0 rgba(0,0,0,0.0);
        box-shadow: 0 0px 0px 0 rgba(0,0,0,0.0), 0 0px 0px 0 rgba(0,0,0,0.0);
    }
</style>