<template>
    <div style="margin-top: -13px;" class="pb-5">
        <mdb-row>
            <mdb-col>
                <mdb-card class="card-image" style="background-image: url(/img/about-bg.jpg)">
                    <div class="text-white text-center py-5 px-4">
                        <div class="py-5">
                            <h1 class="card-title h1 my-4 py-2"><b>EQUIPO</b></h1>
                        </div>
                    </div>
                </mdb-card>
            </mdb-col>
        </mdb-row>
    </div>
</template>
<script>
import { mdbRow, mdbCol, mdbCard } from "mdbvue";
export default {
    components: {
        mdbRow,
        mdbCol,
        mdbCard
    }
}
</script>
<style scoped>
.card {
    border-radius: 0;
}
</style>